import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import usePaymentApi from '../../api/usepaymentapi.hook';
import { checkCheckoutErrors } from '../../utility';
import useUserApi from '../../api/useuserapi.hook';
import { useDispatch, useSelector } from 'react-redux';
import useCarRentalApi from '../../api/userentalcarapi.hook';
import { addBookInfo, carBookGuid } from '../../redux/car/carslice';
import TOASTER_TYPE from '../common/notification/toaster_types.component';
import TOASTER_POSITION from '../common/notification/toaster_positions.component';
import { handleNotify } from '../common/notification/toaster_notify.component';
import { useNavigate } from 'react-router-dom';
const PaymentForm = ({ userDetail, bookDataError, bookFormData, profileErrors }) => {
    const stripe = useStripe();
    const { createPaymentIntent } = usePaymentApi()
    const { updateProfile } = useUserApi();
    const user = useSelector(({ user }) => user?.user)
    const guId = useSelector(({ car }) => car?.guid)
    const userId = user?.user_id;
    const elements = useElements();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [cardHolder, setcardHolder] = useState("")
    const [isLoading, setisLoading] = useState(false);
    const [profileDetail, setProfileDetails] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const bookPeriod = useSelector(({ car }) => car?.bookPeriod)
    const { checkCarAvaibility, bookRentalCar } = useCarRentalApi()

    const cardNumberStyle = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                },
                backgroundColor: '#ffffff',
                padding: '10px 12px',  // Padding inside the input
                border: '1px solid #ced4da',  // Light border color similar to the example
                borderRadius: '4px',  // Rounded corners
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    }


    const handleCheckoutData = async () => {
        const { firstName, middleName, lastName, mobileNumber, emailId, nationality, addressLine1, addressLine2, pinCode, dlNumber, passportNumber, file, } = userDetail;
        const userData = {
            first_name: firstName || "",
            middle_name: middleName || "",
            last_name: lastName || "",
            mobile_number: mobileNumber || "",
            email: emailId || "",
            nationality: nationality || "",
            address_line1: addressLine1 || "",
            address_line2: addressLine2 || "",
            pin_code: pinCode || "",
            dl_number: dlNumber || "",
            passport_number: passportNumber || "",
            // user_img: file || null, // Assuming file is an image or null
            driving_license_file: file || null,
            userId: userId
            // Assuming file is an image or null
        };
        const { selectedCar, pickupDate, dropOffDate, pickupTime, dropOffTime, delivery, address } = bookFormData
        const state = { car: selectedCar, from: pickupDate, to: dropOffDate, pickuptime: pickupTime, dropOffTime: dropOffTime, delivery: delivery, address: address, timePeriod: bookPeriod }
        //first need to check the car is available for the this period
        const carCheckObj = { car_id: selectedCar, book_date_from: pickupDate, book_date_to: dropOffDate, book_pick_time: pickupTime, book_drop_time: dropOffTime }
        const bookCarModal = {
            ...carCheckObj,
            user_id: userId,
            delivery: delivery,
            address: address,
            price: 30,
            status: "active",
            book_frequency: bookPeriod
        }
        return new Promise(async (resolve, reject) => {
            //if book guid is blank than do this
            if (guId === "") {
                debugger
                const res = await checkCarAvaibility(carCheckObj)
                if (res?.isSucess) {
                    if (res?.data?.isAvailable) {
                        dispatch(addBookInfo(state))
                        try {
                            const bookcarRes = await bookRentalCar(bookCarModal)
                            if (bookcarRes && bookcarRes?.isSucess) {
                                const guid = bookcarRes?.data?.guid
                                dispatch(carBookGuid(guid))
                                try {
                                    const data = await updateProfile(userData, userId);
                                    if (data && data?.isSucess) {
                                        resolve({ isSucess: true })
                                    }
                                } catch (error) {

                                }
                            }
                        } catch (err) {
                            reject()
                        }

                    } else {
                        debugger
                        handleNotify(res.message, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT)
                        reject({ isSucess: false })
                        // return;
                    }
                }
            } else {
                try {
                    const data = await updateProfile(userData, userId);
                    if (data && data?.isSucess) {
                        resolve({ isSucess: true })
                    }
                } catch (error) {
                    reject();
                }
            }
            // }


        })
    }


    const handleErrorMessage = (error) => {
        debugger
        if (error) {
            let errorMessage = "Payment failed. Please try again.";
            switch (error.code) {
                case "card_declined":
                    errorMessage = "Your card was declined. Please use a different card.";
                    break;
                case "expired_card":
                    errorMessage = "Your card has expired. Please use a different card.";
                    break;
                case "insufficient_funds":
                    errorMessage = "Insufficient funds. Please check your balance or use a different payment method.";
                    break;
                case "incorrect_cvc":
                    errorMessage = "Incorrect CVC. Please check and re-enter the CVC.";
                    break;
                default:
                    errorMessage = error.message || "An error occurred. Please try again.";
            }
            setisLoading(false)
            setError(errorMessage);
            setSuccess(false)
        }


    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await handleCheckoutData()
            debugger
            if (!data.isSucess) {
                return
            }
            setisLoading(true)
            console.log(data)
            if (cardHolder === "") {
                setError("Please enter cardholder name")
                setisLoading(false)
                return;
            }
            try {
                // const res = await createPaymentIntent({ currency: "aed", amount: 10 })
                const res = await createPaymentIntent({ currency: "inr", amount: 48.00,guId })
                const clientSecret = res.data.clientSecret;
                const cardElement = elements.getElement(CardNumberElement);
                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: cardHolder,
                            // email: 'testuser@example.com',
                            address: {
                                line1: '123 Test Street',
                                line2: 'Near Landmark',
                                city: 'Mumbai',
                                state: 'MH',
                                postal_code: '400001',
                                country: 'IN', // Country code for India
                                //   country: 'AE', // Country code for India
                            },
                        },


                    },
                })
                if (result.error) {
                    handleErrorMessage(result.error)
                }
                else if (result.paymentIntent.status === 'succeeded') {
                    dispatch(carBookGuid(""))
                    setSuccess(true);
                    setError("");
                    navigate("/profile", {
                        state: { activeTab: "livebooking" },
                    });
                }
            }
            catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }


    }

    const handleCardHolderName = (e) => {
        setcardHolder(e.target.value)
        // setisLoading()
    }
    const isError = checkCheckoutErrors(profileErrors, bookDataError)
    return (
        <>
            <div className="row pb-4">
                <div className="col-12">
                    <h3 className="text-theme pt-2">Payment Information</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" checked type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label mt-1 text-theme" htmlFor="flexRadioDefault1">
                                Credit / Debit Card
                            </label>
                        </div>

                    </div>
                    <div className="col-12">

                        {/* <Elements stripe={stripePromise} > */}

                        <div className="row mt-2">
                            {error !== "" && <p className='text-danger'>{error}</p>}
                            <div className="col-3">
                                <label htmlFor="s" className="form-label ms-2"> Card Number </label>
                                <CardNumberElement className='form-control' options={{ showIcon: true }} />
                                {/* <input type="text" className="form-control" id="firstName" name="firstName" /> */}
                            </div>
                            <div className="col-3">
                                <label htmlFor="s" className="form-label ms-2">Expration Date </label>
                                {/* <input type="text" className="form-control" id="firstName" name="firstName" /> */}
                                <CardExpiryElement className='form-control' />
                            </div>
                            <div className="col-3">
                                {/* <label htmlFor="firstName" className="form-label "> Card Holder </label> */}
                                <input type="text" className="form-control h-100" value={cardHolder} onChange={handleCardHolderName} placeholder='Card Holder' id="firstName" name="firstName" />
                            </div>
                            <div className="col-3">
                                <label htmlFor="CVV" className="form-label ms-2">CVV</label>
                                <CardCvcElement className='form-control' />
                                {/* <input type="text" className="form-control" id="firstName" name="firstName" /> */}
                            </div>
                            <div className="col-12 mt-3">
                                <div className='text-end me-3'>
                                    <button className="btn btn-outline-dark px-5 me-2">Cancel</button>
                                    <button className={`btn bg-dark-blue btn text-white px-5 ${(isLoading || isError) ? "cursor-none  pe-none opacity-50 " : ""}`} type='submit'>Proceed</button>
                                </div>
                            </div>
                        </div>
                        {/* </Elements> */}
                    </div>
                </form>
            </div>
        </>
    )
}

export default PaymentForm

import React from 'react'
import { API_RESPONSE_STATUS, ResponseModel, useApi } from './useapi.hook';
import { END_POINT } from '../config';

const usePaymentApi = () => {
    const { handleAxiosPostAsync, handleAxiosGetAsync } = useApi();
    let responseModel = new ResponseModel();

    const createPaymentIntent = async (data) => {
        try {
            responseModel = await handleAxiosPostAsync( data, `${END_POINT.PAYMENT}/createPaymentIntent` );
            if ( responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS ) {
                return responseModel;
            }
        } catch (err) {
            throw new Error(err);
        }
    };
    return {createPaymentIntent}
}

export default usePaymentApi

import { createSlice } from "@reduxjs/toolkit";

export const carSlice = createSlice({
  name: "cars",
  initialState: {
    cars: [],
    bookinfo: null,
    bookPeriod: "monthly",
    selectedcar: null,
    guid: "",
    amount:0
  },
  reducers: {
    addCars: (state, action) => {
      return {
        ...state,
        cars: action.payload,
      };
    },

    addBookInfo: (state, action) => {
      return {
        ...state,
        bookinfo: action.payload,
      };
    },
    addBookPeriod: (state, action) => {
      return {
        ...state,
        bookPeriod: action.payload,
      };
    },

    aadSelectedCar: (state, action) => {
      return {
        ...state,
        selectedcar: action.payload,
      };
    },
    carBookGuid: (state, action) => {
      return {
        ...state,
        guid: action.payload,
      };
    },
    carBookAmount:(state, action)=>{
      return {
        ...state,
        amount: action.payload,
      };
    }
    


  },
});
export const { addCars, addBookInfo, addBookPeriod, aadSelectedCar,carBookGuid } = carSlice.actions;
// export default userSlice.reducer;

import React from 'react'
import { useSelector } from 'react-redux';

const DiscountPromoComponent = () => {
    const selectedCar=useSelector(({car})=>car?.selectedcar)
    const bookPeriod=useSelector(({car})=>car?.bookPeriod)
    return (
        <div className='px-4'>
            <div className="row mt-5">
                <div className="col-4 "><img style={{width:"160px",height:"81px" }}src={`https://car-image-bucket-2024.s3.ap-south-1.amazonaws.com/car/${selectedCar?.img}`} alt="" /></div>
               
                <div className="col-8 d-flex align-items-center ps-5 "><h4>{selectedCar?.title}</h4></div>
                <hr className='text-secondary' />
                
                {/* <div className="col-12 d-flex justify-content-between">
                    <div ><h6 className='fw-lighter'>Monthly Rent</h6></div>
                    <div ><h6 className='fw-lighter'>29 AED</h6></div>
                </div> */}
                {/* <div className="col-12 d-flex justify-content-between">
                    <div ><h6 className='fw-lighter'>Tax</h6></div>
                    <div ><h6 className='fw-lighter'>29 AED</h6></div>
                </div> */}
                {/* <div className="col-12 d-flex justify-content-between mb-2">
                    <div > <input
                                type="text"
                                id="address"
                                value={""}
                                className='form-control'
                                placeholder='Apply promocode'
                                // onChange={(e) => setAddress(e.target.value)}
                                // style={styles.input}
                            /></div>
                    <div ><button className='bg-dark-blue btn text-white px-5 '>Apply</button></div>
                </div> */}
                <div className="col-12 d-flex justify-content-between text-black mt-4">
                    <div ><h5 className='text-capitalize fw-semibold'>Total Price</h5></div>
                    <div >{selectedCar&&<h6 className=''>{selectedCar[`${bookPeriod}_price`]}</h6>}</div>
                </div>
            </div>
        </div>
    )
}

export default DiscountPromoComponent;

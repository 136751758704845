import React, { useMemo, useState } from 'react'
import { Navigation } from '../components/navigation'
import Breadcrumb from '../components/common/bredcum.component'
import Footer from '../components/footer'
import CarBookForm from '../components/common/carbook.component'
import DiscountPromoComponent from '../components/checkout/discountpromo.component'
import ProfileSectionComponent from '../components/profile/profile-section.component'
import PaymentForm from '../components/checkout/payment.component'
import { loadStripe } from '@stripe/stripe-js'
import usePaymentApi from '../api/usepaymentapi.hook'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { differenceInDays } from 'date-fns'

const Checkout = () => {
    const stripePromise = loadStripe('pk_test_51QM1HyLxWKwNDA0WNWtoqkQhwvp29NUvc2NXlYBwghhuAzunNQxg2NCjzRT08IlUCPbx4PPXqx49OAzqXkuamnQJ00gn9YmW56');
    const cars = useSelector(({ car }) => car?.cars)
    const bookData = useSelector(({ car }) => car?.bookinfo)
    const [error, seterror] = useState({})
    const [userData, setUserData] = useState({})
    const [bookFormData, setbookFormData] = useState({})
    const [bookDataError, setbookDataError] = useState({})
    // const { createPaymentIntent } = usePaymentApi()
    // const stripe = useStripe();

    // const elements = useElements();

    // const handleSubmit = async (e, stripe, elements,CardNumberElement) => {
    //     e.preventDefault();
    //     try {
    //         const res = await createPaymentIntent({ currency: "inr", amount: 10 })
    //         const clientSecret = res.data.clientSecret;
    //         const cardElement = elements.getElement(CardNumberElement);
    //         const result = await stripe.confirmCardPayment(clientSecret, {
    //             payment_method: {
    //                 card: cardElement,
    //             },
    //         })
    //         console.log(result)

    //     }
    //     catch (error) {

    //     }

    // }

    const handleProfileError = (error, profilevalue) => {
        seterror(error)
        setUserData(profilevalue)
    }

    const handleCarFormValues = (error, bookData) => {
        setbookDataError(error)
        setbookFormData(bookData)
    }

    const isMoreThan30Days = useMemo(() => {
        if (!bookFormData?.dropOffDate || !bookFormData?.pickupDate) return false;

        const daysDifference = differenceInDays(bookFormData?.dropOffDate, bookFormData?.pickupDate);
        console.log(daysDifference)
        return daysDifference > 30;
    }, [bookFormData?.dropOffDate, bookFormData?.pickupDate]);

    return (
        <>

            <Navigation page="detail"></Navigation>
            <Breadcrumb name="checkout" />
            <div className="container pb-2">
                <div className="row">
                    <div className="col-12">
                        <div className="row price-breakup bg-white py-2">
                            <div className="col-7">
                                <CarBookForm fromPage="Checkout" carData={cars} rentalBookData={bookData} handleCarFormValues={handleCarFormValues} />
                            </div>
                            <div className="col-5">
                                <DiscountPromoComponent />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 px-0">
                        <ProfileSectionComponent page={"Checkout"} handleProfileError={handleProfileError} userDetail={userData} />
                    </div>
                    {!isMoreThan30Days ? <div className="col-12">
                        <Elements stripe={stripePromise} >
                            {stripePromise && <PaymentForm profileErrors={error} userDetail={userData} bookDataError={bookDataError} bookFormData={bookFormData} stripePromise={stripePromise} />}
                        </Elements>
                    </div> :
                        <div className="d-flex justify-content-center">
                            <a href="https://wa.me/971527074847/?text=" className="btn bg-dark-blue text-white px-5">
                                Proceed
                            </a>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Checkout
